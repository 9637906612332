import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Card, CardBody, Col} from 'reactstrap'
import SlideShow from "../slideshow-instances/slideshow-home/slideshow-home"
import HomepageDownloads from "../homepage-downloads/homepage-downloads"

import './_homepage.scss'

const Homepage = () => (
  <StaticQuery 
    query={locationTextQuery} 
    render= {data => { 
      return (
        <div>
          <Content data={data}/>
        </div>
      )
    }}
    />
)

const Content = ({data}) => {
  const content = data.markdownRemark;
  return (
      <Card>
        <CardBody className='post-item'>
            <Col sm={12}> 
                <Col xs={12} sm={6} className='pull-right'>
                    <SlideShow/>
                    <HomepageDownloads/>
                </Col>
                <Col xs={12} sm={6} className='pull-left'>   
                      <div dangerouslySetInnerHTML={{__html: content.html}} />
                </Col>
            </Col>
        </CardBody>
  </Card>
  )
}

const locationTextQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/homepage/content/markdown/"}) {
      id
      frontmatter {
        listClass
      }
      html
    }
  }
`

export default Homepage
