import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { CardTitle } from 'reactstrap'

import './_homepage-downloads.scss'

const HomepageDownloads = () => (
  <StaticQuery 
    query={downloadsQuery} 
    render= {data => { 
      return (
        <div>
          <CardTitle>
              <h4>Downloads</h4>
          </CardTitle>
          <ul className="homepage-downloads">
            {data.allMarkdownRemark.edges.map(({node}) =>(
              <li>
                <a rel="noopener noreferrer" href={node.frontmatter.file.publicURL} target="_blank">{node.frontmatter.title}</a>
              </li>
            ))}
          </ul>
      </div>
      )
    }}
    />
)

const downloadsQuery = graphql`
query {
  allMarkdownRemark (
    sort: { fields: [frontmatter___order], order: ASC},
    filter: {fileAbsolutePath: {regex: "/homepage-downloads/content/markdown/"}}
    ) {
    edges {
      node {
        frontmatter {
          title
          file {
              publicURL
          }
        }
      }
    }
  }
}
`

export default HomepageDownloads
