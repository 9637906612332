import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import Homepage from "../components/homepage/homepage"

const IndexPage = () => (
  <Layout pageTitle={false} sideBar={false} isHomepage={true}>
    <SEO title="Home" />
    <Homepage/>
  </Layout>
)

export default IndexPage
 